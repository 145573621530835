import React, { useEffect, useRef, useState } from 'react';
import {
  ArtistInnerCard as ArtistCard,
  Layout,
  ProjectArtistInnerCard as ProjectCard,
  SEO,
} from '../components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import '../assets/styles/pages/artist.scss';

const Artist = ({ pageContext: { data: ssrData }, ...props }) => {
  const [data, setData] = useState(null);
  const [sectionHeight, setSectionHeight] = useState('auto');

  const relatedArtistsRef = useRef(null);

  console.log('ssrData', ssrData);

  useEffect(() => {
    fetch(`${process.env.API_URL}/api/artists/${ssrData?.slug}`)
      .then((response) => response.json())
      .then((res) => {
        setData(res);
      });
  }, []);

  useEffect(() => {
    const footerHeight = document.getElementById('footer').clientHeight;
    const relatedArtistsSection = relatedArtistsRef.current;
    if (relatedArtistsSection !== null) {
      const relatedArtistsSectionPadding = parseFloat(
        window
          .getComputedStyle(relatedArtistsSection, null)
          .getPropertyValue('padding-bottom')
          .replace('px', '')
      );
      setSectionHeight(
        `calc(100% - ${footerHeight}px - ${relatedArtistsSectionPadding}px)`
      );
    }
  }, [data, props.size]);

  const { name, surname, slug, concerts, projects, related_artist } =
    data !== null && data;
  console.log(data);

  return (
    <>
      <Layout>
        <SEO
          url={`${process.env.BASE_URL}/artists/${ssrData?.slug}/`}
          title={ssrData?.metas?.title || ''}
          image={ssrData?.avatar || ssrData?.project_image}
          description={
            ssrData?.metas?.description ||
            `Explore foremost classical musicians and emerging talents in MusicRoom`
          }
          showDefaultTitle={false}
        />

        <div className='artist__inner'>
          <div className='artist__header__border' />

          <div
            className='artist__details__parent'
            style={{ height: sectionHeight }}
          >
            <ArtistCard data={data} />
          </div>

          <div className='artist projects'>
            {projects?.data?.length ? (
              <div className='artist__content'>
                <div className='artist__content__projects'>
                  <>
                    <h2 className='content__title'>
                      Projects with{' '}
                      <span className='stroked__text'>
                        {name} {surname}
                      </span>
                    </h2>
                    <div className='listing'>
                      {projects?.data?.map((concert) => {
                        return (
                          <ProjectCard
                            key={`${concert.id}-${concert.slug}`}
                            data={concert}
                            artistParam={`/projects/${concert.slug}?artist=${slug}`}
                          />
                        );
                      })}
                    </div>
                  </>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          {related_artist && related_artist.length ? (
            <div
              className='artist related'
              id='artist__inner__related'
              ref={relatedArtistsRef}
            >
              <div className='artist__content'>
                <div className='artist__content__related'>
                  <div className='artist__content__related__head'>
                    <h2 className='content__title'>ARTISTS IN THE ROOM</h2>
                    <AniLink fade duration={0.7} to='/artists'>
                      Enter <div className='arrowIcon' />
                    </AniLink>
                  </div>

                  <div className='listing artistInnerListing'>
                    {related_artist.map((relatedArtist, i) => (
                      <ArtistCard
                        artistInnerRelated
                        to={`/artists/${relatedArtist.slug}`}
                        key={`${relatedArtist.slug}-${i}`}
                        data={relatedArtist}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </Layout>
    </>
  );
};

// Artist.propTypes = {
//     pageContext: object,
//     size: object,
//     location: object,
// };

export default Artist;
